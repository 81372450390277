import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';

const IndexPage = () => (
  <Layout>
    <Seo title={`©${new Date().getFullYear()} | Portfolio of Ryan Johnson`} />

    <h2>Hello.</h2>

    <div>
      <p>
        My name is Ryan Johnson, and I am a designer & developer based in
        Philadelphia. I’m currently a Design Director at{' '}
        <a href="https://madebycraft.co/" target="_blank" rel="noreferrer">
          Craft
        </a>
        .
      </p>
      <p>
        I’m verrry close to launching a new site, but in the meantime you can
        find me on{' '}
        <a
          href="https://www.instagram.com/ryjohnson/"
          target="_blank"
          rel="noreferrer"
        >
          Instagram
        </a>
        ,{' '}
        <a
          href="https://dribbble.com/ryjohnson"
          target="_blank"
          rel="noreferrer"
        >
          Dribbble
        </a>
        ,{' '}
        <a
          href="https://vsco.co/ryjohnson/gallery"
          target="_blank"
          rel="noreferrer"
        >
          VSCO
        </a>
        ,{' '}
        <a href="https://codepen.io/ryjohnson" target="_blank" rel="noreferrer">
          Codepen
        </a>{' '}
        &{' '}
        <a
          href="https://open.spotify.com/user/ryjohnson"
          target="_blank"
          rel="noreferrer"
        >
          Spotify
        </a>
        .
      </p>
    </div>
  </Layout>
);

export default IndexPage;
